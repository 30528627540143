import { createContext, useCallback, useEffect, useState } from "react";
import { getMonthInSpanish } from "../Services/Grafics/getMonthInSpanish";
import { BACKEND_URL } from "../constants/constants";
import { transfers } from "../constants/transfers";
import { groupByLocationAndFilterByAPM } from "../Services/Grafics/transfers/totalTransfersPerLocationAndAPM";
import { groupByPharmacyAndFilterByAPM } from "../Services/Grafics/transfers/totalTransfersPerPharmacyAndAPM";
import { useAuth } from "../hooks/useAuth";
import { toast } from "react-toastify";
import { roles } from "../constants/roles";
import { getRealAPMName } from "../Services/getRealApmNames";

export const transfersContext = createContext();
function normalizeData(dataArray) {
  return dataArray.map(item => ({
    fecha: item.fecha || "",
    cantidad: item.cantidad || 0,
    drogueria: "Venta Directa",  // Campo adicional que no está en el objeto original
    producto: item.Producto || "",
    farmacia: item.contacto || "",
    localidad: "Venta Directa",  // Campo adicional que no está en el objeto original
    estado: "ventaDirecta",  // Valor por defecto
    apm: getRealAPMName(item.apm.split('EQUIPO APM.').join("") )|| ""
  }));
}
const TransfersProvider = ({ children }) => {
  const [chartData, setChartData] = useState([]);
  const [actualMonth, setActualMonth] = useState(new Date().getMonth());
  const [transfersCardPorcentage, setTransfersCardPorcentaje] = useState();
  const [transfersCardTitle, setTransfersCardTitle] = useState(
    `Transfers emitidos en el mes de ${getMonthInSpanish(new Date().getMonth())}`
  );
  const [transfersCardValue, setTransfersCardValue] = useState(false);
  const [dashboardStep, setDashboardStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [infoUnfiltered, setInfoUnfiltered] = useState([])
  const [pharmacyItems, setPharmacyItems] = useState([])
  const [locationItems, setLocationItems] = useState([])
  const {role} = useAuth()
  const API_URL = 'https://script.google.com/macros/s/AKfycbxnFo_AYymX9cqBS33dqhai1zpwFo0xN0BsXlwW_61XZiFY8EqEQu8iZF121HyNmMX4/exec';

  async function getVentasDirectas() {
      try {
          const response = await fetch(API_URL);
          
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
          
          const ventasDirectas = await response.json();
          
          return ventasDirectas;
      } catch (error) {
          console.error('Error al obtener las ventas directas:', error);
          return null; // o maneja el error según tus necesidades
      }
  }

  function filtrarPorMes(arr, mes) {
    return arr.filter(item => {
        const fecha = new Date(item.fecha);
        return fecha.getMonth() + 1 === mes;
    });
}
  const fetchData = useCallback(async (month) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${BACKEND_URL}/transfers/getAll/${month + 2}`);
      const response = await res.json();
      let ventasDirectas = await getVentasDirectas();
      ventasDirectas = normalizeData(filtrarPorMes(ventasDirectas, month+1))
     
      const modelo = [,...response.map((x) => ({
        ...x,
        apm:
          transfers.find(
            (y) => y.localidad.toLowerCase().trim() === x.localidad.toLowerCase().trim()
          )?.apm || "SIN APM",
      }))];
      // setInfoUnfiltered([...modelo.slice(1), ...ventasDirectas]);
      setInfoUnfiltered([...modelo.slice(1)]);
// const groupedData = [...modelo.slice(1), ...ventasDirectas].reduce((acc, curr) => {
const groupedData = [...modelo.slice(1)].reduce((acc, curr) => {
  if(curr){
    const apm = curr.apm.trim();
  const estado = curr.estado === "Facturado" ? "Facturado" : curr.estado === "ventaDirecta" ? "ventaDirecta" : "noFacturado";
  if (!acc[apm]) {
    acc[apm] = { Facturado: 0, noFacturado: 0, ventaDirecta: 0 };
  }

  acc[apm][estado] += curr.cantidad;
  return acc;
  }
  
}, {});
const chartDataa = Object.entries(groupedData)
  .flatMap(([name, values]) => {
    const totalValue = values.Facturado + values.noFacturado; // Suma de Facturado y noFacturado
    return [
      {
        name: name.length > 20 ? name.toUpperCase().substring(0, 20) + "..." : name,
        value: values.Facturado,
        estado: "Facturado",
        totalValue, // Añade el total para ordenar después
      },
      {
        name: name.length > 20 ? name.toUpperCase().substring(0, 20) + "..." : name,
        value: values.noFacturado,
        estado: "noFacturado",
        totalValue, // Añade el total para ordenar después
      },
    ];
  })
  .sort((a, b) => b.totalValue - a.totalValue); // Ordena por la suma total de Facturado y noFacturado

      setChartData(chartDataa);
      setTransfersCardTitle(`Transfers emitidos en el mes de ${getMonthInSpanish(month)}`);
      
      const total = chartDataa.reduce((x, y) => x + y.value, 0);
      setTransfersCardValue(total);

      const anteriorMonthRequest = await fetch(`${BACKEND_URL}/transfers/getAll/${month + 1}`);
      const anteriorMonth = await anteriorMonthRequest.json();
      setTransfersCardPorcentaje(
        Math.ceil((total * 100) / anteriorMonth.reduce((x, y) => x + y.cantidad, 0)) - 100
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (dashboardStep === 0) {
      fetchData(actualMonth);
    }
  }, [actualMonth, dashboardStep, fetchData]);

  const changeMonth = useCallback((direction) => {
    const newMonth = actualMonth + direction;
    const currentMonth = new Date().getMonth();
    if (newMonth >= currentMonth - 2 && newMonth <= currentMonth) {
      setActualMonth(newMonth);
    }
  }, [actualMonth]);

  const previousClick = useCallback(() => changeMonth(-1), [changeMonth]);
  const nextClick = useCallback(() => changeMonth(1), [changeMonth]);

  const handleClick = (event) => {
    const { activeTooltipIndex } =
      event.chartX && event.chartY ? event : { activeTooltipIndex: -1 };





      console.log("Active Tooltip Index:", activeTooltipIndex);
      if (chartData[activeTooltipIndex]) {
          console.log("Data Clicked:", chartData[activeTooltipIndex * 2]);
          console.log(chartData)
      } else {
          console.error("Invalid index or data not found");
      }



    if (activeTooltipIndex * 2 >= 0) {
      if(role.role === roles.SUPERADMIN || role.role === roles.COMERCIALLEADER || role.name.toUpperCase() === chartData[activeTooltipIndex * 2].name.toUpperCase() || chartData[activeTooltipIndex * 2].name.toUpperCase() === 'SIN FICHERO' || chartData[activeTooltipIndex * 2].name.toUpperCase() === 'SIN APM' || chartData[activeTooltipIndex * 2].name.toUpperCase() === 'GR JS') {

        const totalPharmacyTransfers =  groupByPharmacyAndFilterByAPM(
            infoUnfiltered,
            chartData[activeTooltipIndex * 2].name,
          )
          const totalLocationTransfers =  groupByLocationAndFilterByAPM(
            infoUnfiltered,
            chartData[activeTooltipIndex * 2].name,
          )
      setPharmacyItems(
        totalPharmacyTransfers
      );
      setLocationItems(
       totalLocationTransfers
      );
      setDashboardStep(dashboardStep + 1);

      const total = totalPharmacyTransfers.reduce((x, y)=>x + y.cantidad ,0)

      setTransfersCardValue(total);

    setTransfersCardTitle(
        `Transfers emitidos por ${chartData[activeTooltipIndex * 2].name} el mes de ${getMonthInSpanish(actualMonth)}`
      );
    } else{
      toast.error('Acceso denegado. Contactá al equipo de sistemas si creés que esto es un error.')
    }}
  
 
  };

  const contextValue = {
    chartData,
    actualMonth,
    transfersCardTitle,
    transfersCardPorcentage,
    transfersCardValue,
    dashboardStep,
    setDashboardStep,
    previousClick,
    nextClick,
    isLoading,
    pharmacyItems,
    locationItems,
    handleClick
  };

  return (
    <transfersContext.Provider value={contextValue}>
      {children}
    </transfersContext.Provider>
  );
};

export default TransfersProvider;
