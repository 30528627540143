import React, { useContext } from "react";
import PrintButton from "../../../components/Buttons/PrintButton";
import NewButton from "../../../components/Buttons/NewButton";
import { useState } from "react";
import { useEffect } from "react";
import TableRow from "../../../components/TableRow";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import DashboardPageHeading from "../../../components/headings/DashboardPageHeading";
import { BACKEND_URL } from "../../../constants/constants";
import NewMoviment from "../../../components/modals/newMoviment";
import { createExcel } from "../../../hooks/useCreateExcel";
import { ReloadDataContext } from "../../../contexts/reloadDataContext";
import {SelectDataPersonalized} from "../../../components/molecules/filterSelect.js";

const Modal = ({ setModalDate, setDateEnd, setDateInit }) => {
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd1] = useState('');

  const handleSubmit = (e) => {
	e.preventDefault();
	setModalDate(false);
  
	// Convertir las fechas de 'YYYY-MM-DD' a 'DD/MM/YYYY'
	const formatDate = (dateString) => {
	  const [year, month, day] = dateString.split('-');
	  return `${day}/${month}/${year}`;
	};
  
	// Validar si las fechas están seleccionadas correctamente
	if (dateEnd < dateStart) {
	  alert('Error: La fecha "Hasta" no puede ser anterior a la fecha "Desde".');
	  return;
	}
  
	// Convertir las fechas y mostrarlas en el formato correcto
	const formattedStartDate = formatDate(dateStart);
	const formattedEndDate = formatDate(dateEnd);
  
	// Mostrar en la consola el formato correcto
	console.log('Filtros aplicados:', {
	  dateStart: formattedStartDate,
	  dateEnd: formattedEndDate,
	});

	setDateInit(formattedStartDate);
	setDateEnd(formattedEndDate);
  
	// Cerrar el modal

  };
  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-[100000]">
      <div className="bg-white p-6 rounded-lg shadow-lg z-[100000]">
        <h2 className="text-xl font-semibold mb-4">Elige los filtros</h2>
        <form onSubmit={handleSubmit}>
          {/* Campo "Desde" */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Desde:</label>
            <input
              type="date"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
              value={dateStart}
              onChange={(e) => setDateStart(e.target.value)}
            />
          </div>

          {/* Campo "Hasta" */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Hasta:</label>
            <input
              type="date"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
              value={dateEnd}
              onChange={(e) => setDateEnd1(e.target.value)}
            />
          </div>

          {/* Botones "Cancelar" y "Aceptar" */}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              onClick={() => setModalDate(false)}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Aceptar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};




const Moviments = () => {
	const tableHeadItems = [
		"Producto",
		"Cantidad",
		"Nº de remito",
		"Fecha",
		"Tipo de Movimiento",
		"Responsable Interno",
		"Tipo de proveedor",
		"Nombre de proveedor",
		"Nombre de Destinatario",
		"Orden de producción",
		"Producción",
		"Fecha de Vencimiento",
	];
	


	const [createModalState, setCreateModalState] = useState(false);

	const tableHead = (
		<tr>
			{tableHeadItems?.map((tableHeadItem, index) => (
				<th key={index} className="text-xs md:text-2xs lg:text-md">
					{tableHeadItem}
				</th>
			))}
		</tr>
	);

	useEffect(() => {
		if (createModalState) {
			document.getElementsByClassName("drawer-content")[0].style.overflow = "hidden"; // Para asegurar que el scrollbar desaparezca
		} else {
			document.getElementsByClassName("drawer-content")[0].style.overflow = ""; // Restaurar el scrollbar
		}

		/*     return () => {
      document.getElementsByClassName('drawer-content')[0].style.overflow = '';  // Asegurar restauración al desmontar el componente
    }; */
	}, [createModalState]);

	const [moviments, setMoviments] = useState([]);
	const [products, setProducts] = useState([]);

	const { reloadMovement } = useContext(ReloadDataContext);

	const createModalToggle = () => {
		setCreateModalState(!createModalState);
	};

	useEffect(() => {
		fetch(`${BACKEND_URL}/products/all`)
			.then((res) => res.json())
			.then((products) => {
				setProducts(products);
			});
	}, []);

	const [categoriesRemito, setCategoriesRemito] = useState([]);
	const [filtersRemito, setFiltersRemito] = useState([]);
	const [categoriesProduct, setCategoriesProduct] = useState([]);
	const [filtersProduct, setFiltersProduct] = useState([]);


	const setSelectedFiltersRemito = (selectedOptions) => {
		setFiltersRemito(selectedOptions);
	};


	const setSelectedFiltersProduct = (selectedOptions) => {
		setFiltersProduct(selectedOptions);
	};

	  // Función para obtener la fecha de inicio del mes actual en formato 'DD/MM/YYYY'
// Función para obtener la fecha de inicio del mes actual en formato 'DD/MM/YYYY'
// Función para obtener la fecha de inicio del mes actual en formato 'DD/MM/YYYY'// Función para obtener la fecha de inicio del mes actual en formato 'DD/MM/YYYY'
// Función para obtener la fecha de inicio del mes actual en formato 'YYYY-MM-DD'
const getStartOfMonth = () => {
	const date = new Date();
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Aseguramos que el mes sea de dos dígitos
	return `${year}-${month}-01`; // Primer día del mes
  };
  
  // Función para obtener la fecha de fin del mes actual en formato 'YYYY-MM-DD'
  const getEndOfMonth = () => {
	const date = new Date();

    const nextMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 1); // Primer día del próximo mes
	const nextMonthYear = nextMonthDate.getFullYear();
	const nextMonth = (nextMonthDate.getMonth() + 1).toString().padStart(2, '0'); // Asegurar mes de dos dígitos
	const firstDayNextMonth = '01'; // Día 1 del siguiente mes
	return `${nextMonthYear}-${nextMonth}-${firstDayNextMonth}`; // Retorna la fecha en formato 'YYYY-MM-DD'
};

  
  
  
  const [dateInit, setDateInit] = useState(getStartOfMonth());
  const [dateEnd, setDateEnd] = useState(getEndOfMonth());
	

	useEffect(() => {

		fetch(`${BACKEND_URL}/moviments/getAll?dateInit=${dateInit}&dateEnd=${dateEnd}`)
		  .then((res) => res.json())
		  .then((data) => {
			let allMovements = data.data;
			
			/* let set = new Set(allMovements.map((product) => product.referNumber));
			let arraySinDuplicados = [...set];
			setCategoriesRemito(arraySinDuplicados); */
	  
			let set2 = new Set(allMovements.map((product) => product.product));
			let arraySinDuplicados2 = [...set2];
			setCategoriesProduct(arraySinDuplicados2);
	  
			// Obtener los movimientos pendientes y combinarlos
			fetch(`${BACKEND_URL}/moviments/pending`)
			  .then((res) => res.json())
			  .then((pendingData) => {
				let combinedMovements = [ ...pendingData, ...allMovements];
				setMoviments(combinedMovements);
				let set3 = new Set(combinedMovements.map((product) => product.referNumber));
				let arraySinDuplicados3 = [...set3];
				setCategoriesRemito(arraySinDuplicados3);
			  });
		  });
	  }, [reloadMovement, dateInit, dateEnd]);

	  const [modalDate, setModalDate] = useState(false);


	return (
		<section className="p-4 mt-16">
			<DashboardPageHeading
				name="Últimos Movimientos"
				value={moviments.length}
				buttons={[
					<NewButton title={"Cargar nuevo"} modalId="create-new-product" onClick={createModalToggle} />,
					<div
						onClick={() =>
							createExcel(
								moviments,
								`Movimientos a fecha ${new Date().getDate()} ${new Date().getMonth() + 1} ${new Date().getFullYear()}`
							)
						}>
						<PrintButton />
					</div>,
				]}
			/>
			<div className="grid grid-cols-2 gap-x-4 gap-y-2">
				<div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
					<p className="text-xl">Nombre producto</p>
					<SelectDataPersonalized options={categoriesProduct} setSelectedFilters={setSelectedFiltersProduct}/>
				</div>
				<div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
					<p className="text-xl">Numero remito</p>
					<SelectDataPersonalized options={categoriesRemito} setSelectedFilters={setSelectedFiltersRemito}/>
				</div>
			</div>

			{/* create new pharmacy product purchase */}
			{createModalState && (
				<>
					<NewMoviment products={products} createModalToggle={createModalToggle} />
				</>
			)}
			{/* update a pharmacy product */}

			{/* Purchased Table */}


			{moviments.length > 0 ? (
				<table className="table table-zebra table-compact">
					<thead>{tableHead}</thead>
					<tbody>
						{moviments.filter((x) => {
						if (filtersRemito.length > 0 && filtersProduct.length > 0) {
							return filtersRemito.includes(x.referNumber) && filtersProduct.includes(x.product);
						  } else if (filtersRemito.length > 0) {
							return filtersRemito.includes(x.referNumber);
						  } else if (filtersProduct.length > 0) {
							return filtersProduct.includes(x.product);
						  } else {
							return true; // Devuelve todos los movimientos si ambos filtros están vacíos
						  }
					})
							.map((moviment, index) => (
								<TableRow
									key={index}
									tableRowsData={[
										moviment.product,
										moviment.quantity,
										`X-0001-0000${moviment.referNumber}`,
										moviment.date,
										moviment.typeOfMoviment,
										moviment.responsable,
										moviment.typeOfAdressee,
										moviment.suplierName,

										moviment.adresseeName,
										moviment.productionOrder,
										
										moviment.lot,
										moviment.expiration,
										

										<span className="flex items-center gap-x-1">
											{/* <EditButton /> */}
											<DeleteButton
												deleteApiLink="https://stringlab-ims-server.herokuapp.com/api/purchases/pharmacy/"
												deleteURL={"/moviments/delete/"}
												itemId={moviment.id}
												name={moviment.tradeName}
											/>
										</span>,
									]}
									active={moviment.active}
									bonus={moviment.bonus}
								/>
							))}
					</tbody>
				</table>
			) : (
				<table className="table1">
					<tbody className="table-tbody">
						<tr>
							<td className="loading">
								<div className="bar"></div>
							</td>
						</tr>
					</tbody>
				</table>
			)}

			{modalDate && (<Modal setModalDate={setModalDate} setDateInit={setDateInit} setDateEnd={setDateEnd} />)}
		</section>
	);
};

export default Moviments;
