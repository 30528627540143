import React, { useContext, useRef, useState, useEffect } from "react";
import SaveButton from "../../../components/Buttons/SaveButton";
import PrintButton from "../../../components/Buttons/PrintButton";
import NewButton from "../../../components/Buttons/NewButton";
import Input from "../../../components/form/Input";
import ModalCloseButton from "../../../components/Buttons/ModalCloseButton";
import ModalHeading from "../../../components/headings/ModalHeading";
import TableRow from "../../../components/TableRow";
import EditButton from "../../../components/Buttons/EditButton";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import { toast } from "react-toastify";
import DashboardPageHeading from "../../../components/headings/DashboardPageHeading";
import AddModal from "../../../components/modals/AddModal";
import { BACKEND_URL } from "../../../constants/constants";
import { createExcel } from "../../../hooks/useCreateExcel";
import { BiEdit } from "react-icons/bi";
import {SelectDataPersonalized} from "../../../components/molecules/filterSelect.js";
import { ReloadDataContext } from "../../../contexts/reloadDataContext.js";
import LotsModal from "../../../components/modals/LotsModal.js";
import expand from "../../../Assets/expandir.png"
import Select from "../../../components/form/Select.js";

const Products = () => {
	const tableHeadItems = [ "Nombre de Producto", "Categoria", "Mínimo en stock", "Cantidad"];
	const { reloadProduct, setReloadProduct } = useContext(ReloadDataContext);

	const tableHead = (
		<tr>
			{tableHeadItems?.map((tableHeadItem, index) => (
				<th key={index} className="text-xs md:text-2xs lg:text-md">
					{tableHeadItem}
				</th>
			))}
		</tr>
	);

	const [name, setName] = useState("");
	const [updateModal, setUpdateModal] = useState(false);
	const [category, setCategory] = useState("-");
	const [minimunQuantityOnStock, setMinimunQuantityOnStock] = useState("");
	const [filters, setFilters] = useState([]);
	const [categories, setCategories] = useState([]);
	const [products, setProducts] = useState([]);
	const [expandModal, setExpandModal] = useState(false);

	// Ref for the modal checkbox
	const modalCheckboxRef = useRef(null);

	useEffect(() => {
		fetch(`${BACKEND_URL}/products/getAll`)
		  .then((res) => res.json())
		  .then((products) => {
			let set = new Set(products.map((product) => product.category));
			let arraySinDuplicados = [...set];
			setCategories(arraySinDuplicados);
	  
			let set2 = new Set(products.map((product) => product.name));
			let arraySinDuplicados2 = [...set2];
			setCategoriesProduct(arraySinDuplicados2);
	  
			const specialCases = [
			  'ALARGADOR PESTAÑAS COVER WHIT BRUSHES',
			  'ALARGADOR PESTAÑAS ENVASES',
			  'ALARGADOR PESTAÑAS INNER',
			  'VALVULAS GATILLOS',
			  'POMOS S/I/C/T PLASTICO TUBE 30ML',
			  'SILVER PUMP FOR 30ML PERAL ACRYLIC BOTTLE',
			  'VALVULAS CREAM PUMP 24/410',
			  'BOTELLA NUEVO S/I 30ML PEARL ACRYLIC BOTTLE',
			];
	  
			const orderedArray = [
			  'ALCOHOL X 100',
			  'KOLLAGEN X 250',
			  'BY BLOCK BLANCO X 60',
			  'BY BLOCK CLARO X 60',
			  'BY BLOCK OSCURO X 60',
			  'BY BLOCK FLUID SIN COLOR X 75',
			  'BY BLOCK FLUID CLARO X 75',
			  'BY BLOCK FLUID MEDIO X 75',
			  'C+H FACIAL',
			  'CLARIFIER',
			  'CLEANSER AQUA X 100',
			  'CLEANSER AQUA X 250',
			  'CAPILAR FORTE X 30',
			  'CAPILAR FORTE X 60',
			  'CHAMPÚ OX-SA X 250',
			  'CHAMPÚ VT X 250',
			  'CLEANSER GEL X 100',
			  'CLEANSER GEL X 150',
			  'CLEANSER MILK X 100',
			  'CLEANSER MILK X 150',
			  'EYES X 15',
			  'EYES X 30',
			  'FRESH ROXA X 75',
			  'FRESH ROXA X 150',
			  'HYDRA X 50',
			  'HYDRA X 60',
			  'NUTRI FACIAL X 50',
			  'NUTRI FACIAL X 60',
			  'ANTI CELULITE X 200',
			  'NUTRI CORPORAL X 200',
			  'NUTRI UREA 10 X 250',
			  'NUTRI UREA 10 X 400',
			  'RENOVATION SKIN10',
			  'RENOVATION SKIN 5',
			  'REPAIR SKIN',
			  'STEM CELLS PLUS',
			  'STEM GRAPE',
			  'STEM TENSE',
			  'SERUM - EQUILIBRIUM',
			  'SERUM - FILLER B3+B5+AH',
			  'SERUM - RENOVATION SKIN 8',
			];
	  
			const envases = [
			  'BOTELLA UREA 250 C/ IMPRESION',
			  'BOTELLA UREA 400 C/ IMPRESION',
			  'BOTELLA NUTRI CORPORAL 200 C/ IMPRESION',
			  'BOTELLA ANTI CELULITIS C/ IMPRESION',
			  'BOTELLA CLEANSER GEL 150 C/ IMPRESION',
			  'BOTELLA CLEANSER MILK 150 C/ IMPRESION',
			  'VALVULA CREMERAS PARA UREA250Y400/NC/CELU/CLM150/CLG150',
			  'BOTELLA OXSA C/ IMPRESION',
			  'BOTELLA VT C/ IMPRESION',
			  'BOTELLA MICELAR AQUA 100 C/ IMPRESION',
			  'TAPA DISK TOP PARA CAQ250/VT/OXSA',
			  'BOTELLA MICELAR AQUA 100 C/ IMPRESION',
			  'TAPA PARA AQUA 100',
			  'ENVASE RENOVATION SKIN 5 C/ IMPRESION',
			  'ENVASE HYDRA C/ IMPRESION',
			  'ENVASE REPAIR C/ IMPRESION',
			  'ENVASE NUTRI SKIN FACIAL C/ IMPRESION',
			  'TAPAS CAP+PUMP PARA NF/RS/RP/HY',
			  'ENVASE C+ H FACIAL C/ IMPRESION',
			  'ENVASE RENOVATION SKIN10 C/ IMPRESION',
			  'VALVULAS ENVASE 30ML PARA C+HF/RS10',
			  'INNER ENVASE 30ML PARA C+HF/RS10',
			  'CAPUCHON ENVASE 30ML PARA C+HF/RS10',
			  'ENVASE EYES 15 C/ IMPRESION',
			  'ENVASE STEM TENSE C/ IMPRESION',
			  'VALVULAS ENVASE 15ML PARA EYES15/S.TENSE',
			  'INNER ENVASE 15ML PARA EYES15/S.TENSE',
			  'TAPAS ENVASE 15ML PARA EYES15/S.TENSE',
			  'ENVASE SERUM S/ IMPRESION',
			  'GOTERO SERUM',
			  'ENVASE STEM GRAPE C/ IMPRESION',
			  'VALVULA STEM GRAPE',
			  'ENVASE STEM CELLS C/ IMPRESION',
			  'VALVULA STEM CELLS PLUS',
			  'BOTELLA BY BLOCK FLUID CLARO C/ IMPRESION',
			  'BOTELLA BY BLOCK FLUID MEDIO C/ IMPRESION',
			  'VALVULAS PARA LOS FLUID',
			  'BOTELLA FRESH 75 C/ IMPRESION',
			  'VALVULA FRESH 75',
			  'BOTELLA FRESH 150 C/ IMPRESION',
			  'VALVULA FRESH 150',
			];
	  
			// Filtrar productos por categoría 'Producto Final'
			const finalProducts = products.filter(product => product.category === 'Producto Final');
			// Filtrar productos por categoría 'Envases'
			const packagingProducts = products.filter(product => product.category === 'Envases');
			// Filtrar los productos que están en specialCases
			const specialCaseProducts = products.filter(product => specialCases.includes(product.name.trim()));
			// Filtrar los productos que no están en specialCases ni en las categorías 'Producto Final' o 'Envases'
			const otherProducts = products.filter(
			  product => !specialCases.includes(product.name.trim()) && product.category !== 'Producto Final' && product.category !== 'Envases'
			);
	  
			// Ordenar los productos finales según el array proporcionado
			const sortedFinalProducts = finalProducts.sort((a, b) => {
			  return orderedArray.indexOf(a.name.trim()) - orderedArray.indexOf(b.name.trim());
			});
	  
			// Ordenar los productos de envases según el array proporcionado
			const sortedPackagingProducts = packagingProducts.sort((a, b) => {
			  return envases.indexOf(a.name.trim()) - envases.indexOf(b.name.trim());
			});
	  
			// Combinar productos finales, envases y el resto de productos, colocando specialCases al final
			const sortedProducts = [
			  ...sortedFinalProducts,
			  ...sortedPackagingProducts,
			  ...otherProducts,          // El resto de los productos que no son especiales ni de 'Producto Final' ni de 'Envases'
			  ...specialCaseProducts,    // Los productos en specialCases al final
			];
	  
			setProducts(sortedProducts);
		  });
	  }, [reloadProduct]);
	  



	const [productModal, setProductModal] = useState();

	const alterExpandModal = () => {
		setExpandModal(!expandModal);
	};

	const addNewProduct = (event) => {
		event.preventDefault();
		const productDetails = { name: name.toUpperCase(), category, minimunQuantityOnStock, quantity: 0 };

		fetch(`${BACKEND_URL}/products/add`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
			},
			body: JSON.stringify(productDetails),
		})
			.then((res) => {
				toast.success(<AddModal name={"Producto"} />);
				setReloadProduct((prev) => !prev);
				modalCheckboxRef.current.checked = false; // Cerrar el modal
				res.json();
			})
			.catch(() => toast.error("Hubo un error al agregar el producto 😢"));

		event.target.reset();
	};

	const setSelectedFilters = (selectedOptions) => {
		setFilters(selectedOptions);
	};

	const [categoriesProduct, setCategoriesProduct] = useState([]);
	const [filtersProduct, setFiltersProduct] = useState([]);


	const setSelectedFiltersProduct = (selectedOptions) => {
		setFiltersProduct(selectedOptions);
	};

	useEffect(() => {
		if(filters.length > 0){
			let newFilter = products.filter((x) => {
				if (filters.length > 0) return filters.includes(x.category);
				else return x;
			})

			let set3 = new Set(newFilter.map((product) => product.name));
			let arraySinDuplicados3 = [...set3];
			
			setCategoriesProduct(arraySinDuplicados3);
			
		}else{
			let set2 = new Set(products.map((product) => product.name));
			let arraySinDuplicados2 = [...set2];
			setCategoriesProduct(arraySinDuplicados2);
		}
		
	}, [filters]);

	const formatDate = (date) => {
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses de 0-11
		const year = date.getFullYear();
	  
		return `${day}/${month}/${year}`; // Formato DD/MM/YYYY
	  };
	  
	  const uploadExcel = async (event) => {
		for (const product of products) {
		  const currentDate = formatDate(new Date());
	  
		  const formData = new URLSearchParams({
			Nombre: product.name,
			Categoria: product.category,
			MinimoStock: product.minimunQuantityOnStock,
			Cantidad: product.quantity,
			Fecha: currentDate
		  });
	  
		  try {
			const response = await fetch('https://script.google.com/macros/s/AKfycbyy5rU47xtxqOG4wosaUcYyWShnWJlDc2P5qxux6th6fTrjmnvkjSoOsIpxlKpxpOQbJg/exec', {
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			  },
			  body: formData
			});
	  
			if (!response.ok) {
			  throw new Error(`HTTP error! status: ${response.status}`);
			}
	  
			const data = await response.text();
			console.log(`Product ${product.name} uploaded successfully:`, data);
		  } catch (error) {
			console.error(`Error uploading product ${product.name}:`, error);
		  }
		}
	  };
	  
	  

	return (
		<section className="p-4 mt-16">
			{updateModal && <EditButton id={updateModal} setModal={setUpdateModal} />}
			<DashboardPageHeading
				name="Productos"
				value={
					products.filter((x) => {
						if (filters.length > 0) return filters.includes(x.product);
						else return x;
					}).length
				}
				buttons={[
					<NewButton title={"Crear"} modalId="create-new-product" />,
					<div
						onClick={() =>
							createExcel(
								filters,
								products,
								`stock valorizado a fecha ${new Date().getDate()}_${new Date().getMonth() + 1}_${new Date().getFullYear()}`
							)
						}>
						<PrintButton />
					</div>,
					<div onClick={uploadExcel} className="cursor-pointer">
						subir excel
					</div>
				]}
			/>

			<input type="checkbox" id="create-new-product" className="modal-toggle" ref={modalCheckboxRef} />
			<label htmlFor="create-new-product" className="modal h-screen z-[1000]">
				<label className="modal-box lg:w-7/12 md:w-10/12 w-11/12 max-w-4xl relative" htmlFor="">
					<ModalCloseButton modalId={"create-new-product"} />

					<ModalHeading modalHeading={"Crear Nuevo Producto"} />

					<form onSubmit={addNewProduct}>
						<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 mb-2">
							<Input
								onchange={(e) => {
									setName(e.target.value);
								}}
								title={"Nombre"}
								type="text"
								placeholder="Cleanser Aqua 150ml"
								name="aricleName"
								isRequired="required"
							/>
							<div className="div">
            					<label className="label">
                					<span className="label-text">Categoría(*)</span>
            					</label>
            					<select defaultValue={`Elija una opción`} className="select select-bordered select-xs h-[38px] rounded-[4px]  w-full max-w-xs" onChange={(e) => setCategory(e.target.value)}>
                					<option disabled>Elija una opción</option>
                					{
                    					categories.map((option, index) => <option key={index}>{option}</option>)
                					}
            					</select>
        					</div>
							<Input
								onchange={(e) => {
									setMinimunQuantityOnStock(e.target.value);
								}}
								title={"Cantidad Mínima en Stock"}
								type="number"
								placeholder="250"
								name="quantity"
								isRequired="required"
							/>
						</div>
						<SaveButton extraClass={"mt-4 w-full"} />
					</form>
				</label>
			</label>

			<div className="grid grid-cols-2 gap-x-4 gap-y-2">
				<div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
					<p className="text-xl">Nombre producto</p>
					<SelectDataPersonalized options={categoriesProduct} setSelectedFilters={setSelectedFiltersProduct}/>
				</div>
				<div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
					<p className="text-xl">Categoría</p>
					<SelectDataPersonalized options={categories} setSelectedFilters={setSelectedFilters} />
				</div>
			</div>

			{products.length > 0 ? (
				<table className="table table-zebra table-compact">
					<thead>{tableHead}</thead>
					<tbody>
						{products
							.filter((x) => {
								/* if (filters.length > 0) return filters.includes(x.category);
								else return x; */
								if (filters.length > 0 && filtersProduct.length > 0) {
									return filters.includes(x.category) && filtersProduct.includes(x.name);
								  } else if (filters.length > 0) {
									return filters.includes(x.category);
								  } else if (filtersProduct.length > 0) {
									return filtersProduct.includes(x.name);
								  } else {
									return true; // Devuelve todos los movimientos si ambos filtros están vacíos
								  }
							})
							.map((product, index) => (
								<TableRow
									key={product.id}
									tableRowsData={[
										product.name.toUpperCase(),
										product.category,
										product.minimunQuantityOnStock,
										product.quantity,
										"",
										<span className="flex items-center gap-x-1">
											<label
												onClick={() => {
													setUpdateModal(product.id);
												}}
												htmlFor="update-pharmacy-product"
												className={`gap-x-2 cursor-pointer modal-button z-10 block p-1 text-blue-700 transition-all bg-blue-100 border-2 border-white rounded-full active:bg-blue-50 hover:scale-110 focus:outline-none focus:ring`}>
												<BiEdit />
											</label>
											<DeleteButton deleteURL={"/products/delete/"} itemId={product.id} />
											<button className="ml-6 hover:scale-110 duration-300" onClick={()=>{alterExpandModal(); setProductModal(product)  }}>
												<img src={expand} alt="" className="w-4"/>
											</button>
										</span>,
									]}
								/>
							))}
					</tbody>
				</table>
			) : (
				<table className="table1">
					<tbody>
						<tr>
							<td className="loading">
								<div className="bar"></div>
							</td>
						</tr>
					</tbody>
				</table>
			)}
			{
				expandModal && (
					<LotsModal setExpandModal={setExpandModal} product={productModal}/>
				)
			}
		</section>
	);
};

export default Products;
