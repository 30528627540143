import doctorPerAPm from "../constants/doctorPerApm";

function mapApmToData(apmList, dataList) {
	dataList.forEach((data) => {
		const fullName = `${data.doctorName?.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, "").split("  ").map((x) => x.trim()).join(" ")} ${data.doctorLastName
			?.toUpperCase()
			.replace(/[^a-zA-Z0-9 ]/g, "")}`;


		const matchingApm = apmList.find((apm) => {
			if (apm) {
				const apmFullName = `${apm.NOMBRE?.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, "")} ${apm.APELLIDO?.toString()
					.toUpperCase()
					.replace(/[^a-zA-Z0-9 ]/g, "")}`;
				return fullName.includes(apmFullName) || apmFullName.includes(fullName);
			}
		});
		if (matchingApm) {
			data.apm = `${matchingApm["APM NOMBRE"]}`;
		} else data.apm = "Sin asignar";
	});
	return dataList;
}

export default mapApmToData;
