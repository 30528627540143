import React, { useContext, useState, useEffect } from "react";
import TableRow2 from "../../../components/TableRow2";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import AcceptButton from "../../../components/Buttons/AcceptButton";
import { BACKEND_URL } from "../../../constants/constants";
import { ReloadDataContext } from "../../../contexts/reloadDataContext";
import {SelectDataPersonalized} from "../../../components/molecules/filterSelect.js";
import EditButtonMoviment from "../../../components/Buttons/EditButtonMoviment.js";
import { BiEdit } from "react-icons/bi";
import { toast } from "react-toastify";
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function filterDuplicatesByProductAndReferNumber(data) {
  // Creamos un mapa para agrupar por `product` y `referNumber`
  const groupedItems = data.reduce((acc, item) => {
    const key = `${item.product}-${item.referNumber}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  // Filtramos los elementos que tienen más de una ocurrencia en el mismo grupo
  const duplicates = Object.values(groupedItems).filter(group => group.length > 1);

  // Aplanamos los grupos para obtener un solo array con todos los duplicados
  return duplicates.flat();
}

// Ejemplo de uso
const data = [
  // Incluye aquí tu array de objetos
];


const StockAdmin = () => {
  const [moviments, setMoviments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterRemito, setFilterRemito] = useState([]);
  const [selectedFilterRemito, setSelectedFilterRemito] = useState([]);
  const [filterDate, setFilterDate] = useState([]);
  const [selectedFilterDate, setSelectedFilterDate] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);

  const { reloadPending, setReloadPending } = useContext(ReloadDataContext);

  const tableHeadItems = [
    "Producto",
    "Cantidad",
    "Nº de remito",
    "Fecha",
    "Tipo de Movimiento",
    "Responsable Interno",
    "Tipo de proveedor",
    "Nombre de proveedor",
    "Nombre de Destinatario",
    "Orden de producción",
    "Producción",
    "Fecha de Vencimiento",
  ];

  // Maneja la selección de filas, agregando todo el objeto del movimiento seleccionado
  const handleRowCheckboxChange = (moviment) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.some((row) => row.id === moviment.id)
        ? prevSelectedRows.filter((row) => row.id !== moviment.id)
        : [...prevSelectedRows, moviment]
    );
  };

  // Maneja la selección de todas las filas
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      if (selectedFilterRemito.length > 0) {
        setSelectedRows(moviments.filter((row) => selectedFilterRemito.includes(row.referNumber)));
      }else {
        setSelectedRows(moviments);
      }
    } else {
      setSelectedRows([]);
    }
  };

  function agruparYSumarCantidades(arr) {
    const acumulador = {};
    const repetidos = {}; // Objeto para almacenar los nombres y remitos que se repiten

    arr.forEach(item => {
        // Creamos una clave única para agrupar por 'product' y 'referNumber'
        const clave = `${item.product}_${item.referNumber}`;

        // Si la clave ya existe, significa que es un duplicado
        if (acumulador[clave]) {
            // Agregarlo al objeto de repetidos
            if (!repetidos[clave]) {
                repetidos[clave] = { product: item.product, referNumber: item.referNumber };
            }
        } else {
            // Si no está, lo agregamos al acumulador
            acumulador[clave] = { ...item };
        }
    });

    // Consologuear los repetidos
    console.log("Productos y remitos repetidos:", Object.values(repetidos));

    // Devolver el acumulador como un array de objetos únicos
    return Object.values(acumulador);
}
  // Acepta todos los productos seleccionados
  const handleAcceptSelected = async () => {
    const filteredData = filterDuplicatesByProductAndReferNumber(selectedRows);
console.log(filteredData);
    // Procesar cada producto de forma secuencial
    for (const moviment of selectedRows) {
      try {
        await fetch(`${BACKEND_URL}/moviments/accept/${moviment.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            quantity: moviment.quantity, // Asegurarse de enviar la cantidad correcta
            // Incluir otros datos si es necesario
          }),
        });
  
        // Recargar los datos después de cada petición exitosa
        delay(2000)
        setReloadPending((prev) => !prev);
      } catch (error) {
        console.error(`Error al procesar el producto ${moviment.id}:`, error);
      }

    }
  
    // Limpiar la selección de filas
    setSelectedRows([]);
  
    // Mostrar mensaje de éxito al finalizar
    toast.success(<span>Movimiento agregado con éxito</span>);
  };

  // Elimina todos los productos seleccionados
  const handleDeleteSelected = async () => {
    for (const moviment of selectedRows) {
      try {
        // Lógica para eliminar el producto (hacer llamada a la API)
        await fetch(`${BACKEND_URL}/moviments/deletePending/${moviment.id}`, {
          method: "DELETE",
        });
      } catch (error) {
        console.error(`Error al eliminar el movimiento con ID ${moviment.id}:`, error);
        toast.error(<span>Error al eliminar el movimiento</span>);
        return; // Detener el proceso si ocurre un error
      }
    }
  
    setReloadPending((prev) => !prev);
    setSelectedRows([]);
    toast.success(<span>Movimiento eliminado con éxito</span>);
  };

  useEffect(() => {
    fetch(`${BACKEND_URL}/moviments/pending`)
      .then((res) => res.json())
      .then((moviments) => {
        const uniqueRemitos = new Set(
          moviments.map((moviment) => moviment.referNumber)
        );
        const uniqueDates = new Set(
          moviments.map((moviment) => moviment.date.split(",")[0])
        );

        setFilterRemito([...uniqueRemitos]);
        setFilterDate([...uniqueDates]);
        setMoviments(moviments);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [reloadPending]);

  const allFilteredRowsSelected = selectedRows.length > 0 && 
  selectedRows.length === moviments.filter((row) => {
    if (selectedFilterRemito.length > 0) {
      return selectedFilterRemito.includes(row.referNumber);
    } else {
      return true; // No hay filtro aplicado, incluir todos
    }
  }).length;


  const tableHead = (
    <tr>
      <th>
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={allFilteredRowsSelected}
        />
      </th>
      {tableHeadItems.map((tableHeadItem, index) => (
        <th key={index} className="text-xs md:text-2xs lg:text-md">
          {tableHeadItem}
        </th>
      ))}
    </tr>
  );


  return (
    <section className="p-4 mt-20">
      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        <div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
          <p className="text-xl">Numero de remito</p>
          <SelectDataPersonalized
            options={filterRemito}
            setSelectedFilters={setSelectedFilterRemito}
          />
        </div>
        <div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
          <p className="text-xl">Fecha de remito</p>
          <SelectDataPersonalized
            options={filterDate}
            setSelectedFilters={setSelectedFilterDate}
          />
        </div>
      </div>
      {moviments.length > 0 ? (
        <div className="flex justify-between my-4">
          <button onClick={handleAcceptSelected} className="btn btn-success">
           Descontar o agregar movimiento seleccionado
          </button>
          <button onClick={handleDeleteSelected} className="btn btn-danger">
            Eliminar movimiento seleccionado
          </button>
        </div>
      ) : null}
      {loading ? (
        <table className="table1">
          <tbody className="table-tbody">
            <tr>
              <td className="loading">
                <div className="bar"></div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : moviments.length > 0 ? (
        <table className="table table-zebra table-compact">
          <thead>{tableHead}</thead>
          <tbody>
            {moviments
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .filter((x) => {
						if (selectedFilterRemito.length > 0 && selectedFilterDate.length > 0) {
							return selectedFilterRemito.includes(x.referNumber) && selectedFilterDate.includes(x.date.split(",")[0]);
						  } else if (selectedFilterRemito.length > 0) {
							return selectedFilterRemito.includes(x.referNumber);
						  } else if (selectedFilterDate.length > 0) {
							return selectedFilterDate.includes(x.date.split(",")[0]);
						  } else {
							return true; // Devuelve todos los movimientos si ambos filtros están vacíos
						  }
            })
              .map((moviment, index) => (
                <TableRow2
                  key={index}
                  bonus={moviment.bonus}
                  tableRowsData={[
                    moviment.product,
                    moviment.quantity,
                    `X-0001-0000${moviment.referNumber}`,
                    moviment.date,
                    moviment.typeOfMoviment,
                    moviment.responsable,
                    moviment.typeOfAdressee,
                    moviment.suplierName,
                    moviment.adresseeName,
                    moviment.productionOrder,
                    moviment.lot,
                    moviment.expiration,
                    <span className="flex items-center gap-x-4">
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.some(
                            (row) => row.id === moviment.id
                          )}
                          onChange={() => handleRowCheckboxChange(moviment)}
                        />
                      </td>
                      <DeleteButton
                        deleteURL={"/moviments/deletePending/"}
                        itemId={moviment.id}
                      />
                      <AcceptButton
                        acceptURL={"/moviments/accept/"}
                        itemId={moviment.id}
                      />
                      <label
                        onClick={() => setUpdateModal(moviment)}
                        htmlFor="update-pharmacy-product"
                        className={`gap-x-2 cursor-pointer modal-button z-10 block p-1 text-blue-700 transition-all bg-blue-100 border-2 border-white rounded-full active:bg-blue-50 hover:scale-110 focus:outline-none focus:ring`}
                      >
                        <BiEdit />
                      </label>
                      {updateModal && (
                        <EditButtonMoviment
                          moviment={updateModal}
                          setModal={setUpdateModal}
                        />
                      )}
                    </span>,
                  ]}
                />
              ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center text-lg mt-4">
          No hay movimientos pendientes
        </div>
      )}
    </section>
  );
};

export default StockAdmin;
