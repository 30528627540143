import React from 'react';
import Select, { components } from 'react-select';


const DropdownIndicator = (props
) => {
  return (
    <components.DropdownIndicator {...props}>
      🔎
    </components.DropdownIndicator>
  );
};
  

  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      backgroundColor: 'red',
      color: 'white', 
      padding: '4px 8px', 
      borderRadius: '4px', 
      fontWeight: 'bold', 
    }),
  };
 


  export default function SelectData({
    options,
    setSelectedFilters,
    customStyles,
    styles1,
    prev,
    setEditedProductName, // Recibe la función
  }) {
    const handleChange = (selectedOptions) => {
      const selectedValues = selectedOptions.map((x) => x.value);
  
      // Unir los valores seleccionados en un solo string con el separador '+'
      const joinedValues = selectedValues.join(' + ');
  
      // Actualizar los filtros si existe la función setSelectedFilters
      if(setSelectedFilters){
        setSelectedFilters(selectedOptions.map(x=>x.value))

      }
  
      // Actualizar el nombre del producto con los valores unidos si la función está definida

        setEditedProductName(joinedValues);

    };
  
    const defaultOptions = prev ? prev.map((x) => ({ value: x, label: x })) : [];
  
    return (
      <Select
        closeMenuOnSelect={true}
        defaultValue={defaultOptions} // Pasar las opciones previas aquí
        isMulti
        options={options.map((x) => ({ value: x, label: x }))}
        styles={customStyles}
        maxMenuHeight={styles1}
        onChange={handleChange} // Capturar cambios en las selecciones
      />
    );
  }
  
  export const SelectDataPersonalized = ({ options, setSelectedFilters, styles }) => {
    const handleChange = (selectedOptions) => {
      // Aquí mantenemos las opciones previamente seleccionadas y agregamos las nuevas
      if (setSelectedFilters) {
        setSelectedFilters(selectedOptions.map((x) => x.value)); // Actualizamos con todas las seleccionadas
      }
    };
  
    return (
      <Select
        closeMenuOnSelect={false} // Permitir múltiples selecciones
        defaultValue={[]}
        isMulti
        options={options.map((x) => ({ value: x, label: x }))}
        onChange={handleChange}
        className={`${styles}`}
      />
    );
  };
  
      