import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Search, QrCode, Edit, Save } from "lucide-react"; // Iconos para búsqueda y QR
import { BACKEND_URL } from "../constants/constants";
import QRCode from "qrcode"; // Librería para generar QR
import { jsPDF } from "jspdf";
import { Html5QrcodeScanner } from "html5-qrcode"; // Librería para escanear códigos QR
import SelectData, {SelectDataPersonalized}  from "../components/molecules/filterSelect.js";

// Componente para el escáner QR en un modal
const ScannerModal = ({ isOpen, onClose, onDetected }) => {
  useEffect(() => {
    if (isOpen) {
      const scanner = new Html5QrcodeScanner("reader", { fps: 10, qrbox: 250 });

      scanner.render(
        (decodedText) => {
          console.log(`Código QR detectado: ${decodedText}`);
          onDetected(decodedText); // Llama al manejador cuando se detecta un código QR
          onClose(); // Cierra el modal cuando se detecta el QR
        },
        (error) => {
          console.error("Error al escanear el QR: ", error);
        }
      );

      return () => {
        scanner.clear(); // Limpia el escáner cuando el componente se desmonta
      };
    }
  }, [isOpen, onDetected, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg relative">
        <h2 className="text-2xl font-bold mb-4">Escanea el código QR</h2>
        <div id="reader" style={{ width: "320px" }} />
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

// Componente para el modal con el código QR generado
const ZoneModal = ({ selectedSection }) => {
  const qrRef = useRef(null);

  useEffect(() => {
    if (selectedSection) {
      // Generar el código QR
      QRCode.toCanvas(
        qrRef.current,
        selectedSection.id,
        { width: 1000 },
        (error) => {
          if (error) console.error("Error al generar el QR:", error);
        }
      );
    }
  }, [selectedSection]);

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Obtener la imagen del canvas
    const qrImage = qrRef.current.toDataURL("image/png");

    // Agregar la imagen al PDF
    doc.text(`Zona: ${selectedSection.id}`, 10, 10); // Añadir un texto al PDF (nombre de la zona)
    doc.addImage(qrImage, "PNG", 10, 20, 100, 100); // Añadir el código QR al PDF

    // Descargar el PDF
    doc.save(`CodigoQR_${selectedSection.id}.pdf`);
  };

  return (
    <div>
      <canvas ref={qrRef} style={{ display: "none" }}></canvas>
      <button
        onClick={handleDownloadPDF}
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        Descargar código QR
      </button>
    </div>
  );
};

const sections = [
  { id: "A", color: "bg-white", gridArea: "A", label: "A", clickable: true },
  { id: "B", color: "bg-white", gridArea: "B", label: "B", clickable: true },
  { id: "C", color: "bg-white", gridArea: "C", label: "C", clickable: true },
  { id: "D", color: "bg-white", gridArea: "D", label: "D", clickable: true },
  { id: "E", color: "bg-white", gridArea: "E", label: "E", clickable: true },
  { id: "F", color: "bg-white", gridArea: "F", label: "F", clickable: true },
  { id: "G", color: "bg-white", gridArea: "G", label: "G", clickable: true },
  { id: "H", color: "bg-white", gridArea: "H", label: "H", clickable: true },
  { id: "I", color: "bg-white", gridArea: "I", label: "I", clickable: true },
  { id: "J", color: "bg-white", gridArea: "J", label: "J", clickable: true },
  { id: "K", color: "bg-white", gridArea: "K", label: "K", clickable: true },
  { id: "L", color: "bg-white", gridArea: "L", label: "L", clickable: true },
  { id: "M", color: "bg-white", gridArea: "M", label: "M", clickable: true },
  { id: "N", color: "bg-white", gridArea: "N", label: "N", clickable: true },
  { id: "O", color: "bg-white", gridArea: "O", label: "O", clickable: true },
  { id: "P", color: "bg-white", gridArea: "P", label: "P", clickable: true },
  { id: "Q", color: "bg-white", gridArea: "Q", label: "Q", clickable: true },
  { id: "R", color: "bg-white", gridArea: "R", label: "R", clickable: true },
  { id: "S", color: "bg-white", gridArea: "S", label: "S", clickable: true },
  { id: "T", color: "bg-white", gridArea: "T", label: "T", clickable: true },
  { id: "U", color: "bg-white", gridArea: "U", label: "U", clickable: true },
  { id: "V", color: "bg-white", gridArea: "V", label: "V", clickable: true },
  { id: "W", color: "bg-white", gridArea: "W", label: "W", clickable: true },
  { id: "X", color: "bg-white", gridArea: "X", label: "X", clickable: true },
  { id: "Y", color: "bg-white", gridArea: "Y", label: "Y", clickable: true },
  { id: "Z", color: "bg-white", gridArea: "Z", label: "Z", clickable: true },
  { id: "AA", color: "bg-white", gridArea: "AA", label: "AA", clickable: true },
  { id: "AB", color: "bg-white", gridArea: "AB", label: "AB", clickable: true },
  { id: "AC", color: "bg-white", gridArea: "AC", label: "AC", clickable: true },
  { id: "AE", color: "bg-white", gridArea: "AE", label: "AE", clickable: true },
  { id: "AF", color: "bg-white", gridArea: "AF", label: "AF", clickable: true },
  { id: "AI", color: "bg-white", gridArea: "AI", label: "AI", clickable: true },
  { id: "AJ", color: "bg-white", gridArea: "AJ", label: "AJ", clickable: true },
  { id: "AK", color: "bg-white", gridArea: "AK", label: "AK", clickable: true },
  { id: "AO", color: "bg-white", gridArea: "AO", label: "AO", clickable: true },
  { id: "AP", color: "bg-white", gridArea: "AP", label: "AP", clickable: true },
  { id: "AQ", color: "bg-white", gridArea: "AQ", label: "AQ", clickable: true },
  { id: "AR", color: "bg-white", gridArea: "AR", label: "AR", clickable: true },
  { id: "AS", color: "bg-white", gridArea: "AS", label: "AS", clickable: true },
  {
    id: "CAJAS-CON-PRODUCTOS",
    color: "bg-orange-500",
    gridArea: "CAJAS-CON-PRODUCTOS",
    label: "",
    clickable: false,
  },
  {
    id: "CAJAS-VACIAS",
    color: "bg-slate-500",
    gridArea: "CAJAS-VACIAS",
    label: "",
    clickable: false,
  },
  {
    id: "CAJA-CON-BOLSAS",
    color: "bg-blue-500",
    gridArea: "CAJA-CON-BOLSAS",
    label: "",
    clickable: false,
  },
  {
    id: "CAJAS-VACIASS",
    color: "bg-slate-500",
    gridArea: "CAJAS-VACIASS",
    label: "",
    clickable: false,
  },
  {
    id: "CAJAS-VACIASSS",
    color: "bg-slate-500",
    gridArea: "CAJAS-VACIASSS",
    label: "",
    clickable: false,
  },
  {
    id: "CAJAS-VACIASSSS",
    color: "bg-slate-500",
    gridArea: "CAJAS-VACIASSSS",
    label: "",
    clickable: false,
  },
  {
    id: "CAJAS-VACIASSSSS",
    color: "bg-slate-500",
    gridArea: "CAJAS-VACIASSSSS",
    label: "",
    clickable: false,
  },
  
  {
    id: "PALET-CON-CAJAS-VACIAS",
    color: "bg-yellow-800",
    gridArea: "PALET-CON-CAJAS-VACIAS",
    label: "",
    clickable: false,
  },
  {
    id: "PALET-Y-CAJAS-VACIAS",
    color: "bg-pink-300",
    gridArea: "PALET-Y-CAJAS-VACIAS",
    label: "",
    clickable: false,
  },
  {
    id: "CAJA-CON-PRODUCTO",
    color: "bg-orange-500",
    gridArea: "CAJA-CON-PRODUCTO",
    label: "",
    clickable: false,
  },
  {
    id: "PALET-Y-CAJAS-VACIASS",
    color: "bg-yellow-800",
    gridArea: "PALET-Y-CAJAS-VACIASS",
    label: "",
    clickable: false,
  },
  {
    id: "PALET-Y-CAJAS-VACIASSS",
    color: "bg-yellow-800",
    gridArea: "PALET-Y-CAJAS-VACIASSS",
    label: "",
    clickable: false,
  },
  {
    id: "PALET-Y-CAJAS-VACIASSSSS",
    color: "bg-yellow-800",
    gridArea: "PALET-Y-CAJAS-VACIASSSSS",
    label: "",
    clickable: false,
  },
  { id: "DIV", color: "bg-black text-white mt-8 mb-4", gridArea: "DIV", label: "DEPOSITO PARTE DELANTERA", clickable: false },
  { id: "DIV1", color: "bg-black text-white mt-8 mb-4", gridArea: "DIV1", label: "DEPOSITO PARTE TRASERA", clickable: false },
  { id: "A1", color: "bg-white", gridArea: "A1", label: "A1", clickable: true },
  { id: "B1", color: "bg-white", gridArea: "B1", label: "B1", clickable: true },
  { id: "C1", color: "bg-white", gridArea: "C1", label: "C1", clickable: true },
  { id: "D1", color: "bg-white", gridArea: "D1", label: "D1", clickable: true },
  { id: "E1", color: "bg-white", gridArea: "E1", label: "E1", clickable: true },
  { id: "F1", color: "bg-white", gridArea: "F1", label: "F1", clickable: true },
  { id: "G1", color: "bg-white", gridArea: "G1", label: "G1", clickable: true },
  { id: "H1", color: "bg-white", gridArea: "H1", label: "H1", clickable: true },
  { id: "I1", color: "bg-white", gridArea: "I1", label: "I1", clickable: true },
  { id: "J1", color: "bg-white", gridArea: "J1", label: "J1", clickable: true },
  { id: "K1", color: "bg-white", gridArea: "K1", label: "K1", clickable: true },
  { id: "L1", color: "bg-white", gridArea: "L1", label: "L1", clickable: true },
  { id: "M1", color: "bg-white", gridArea: "M1", label: "M1", clickable: true },
  { id: "N1", color: "bg-white", gridArea: "N1", label: "N1", clickable: true },
  { id: "O1", color: "bg-white", gridArea: "O1", label: "O1", clickable: true },
  { id: "P1", color: "bg-white", gridArea: "P1", label: "P1", clickable: true },
  { id: "Q1", color: "bg-white", gridArea: "Q1", label: "Q1", clickable: true },
  { id: "R1", color: "bg-white", gridArea: "R1", label: "R1", clickable: true },
  { id: "S1", color: "bg-white", gridArea: "S1", label: "S1", clickable: true },
  { id: "T1", color: "bg-white", gridArea: "T1", label: "T1", clickable: true },
  { id: "U1", color: "bg-white", gridArea: "U1", label: "U1", clickable: true },
  { id: "V1", color: "bg-white", gridArea: "V1", label: "V1", clickable: true },
  { id: "W1", color: "bg-white", gridArea: "W1", label: "W1", clickable: true },
  { id: "X1", color: "bg-white", gridArea: "X1", label: "X1", clickable: true },
  { id: "Y1", color: "bg-white", gridArea: "Y1", label: "Y1", clickable: true },
  { id: "Z1", color: "bg-white", gridArea: "Z1", label: "Z1", clickable: true },
  { id: "A2", color: "bg-white", gridArea: "A2", label: "A2", clickable: true },
  { id: "B2", color: "bg-white", gridArea: "B2", label: "B2", clickable: true },
  { id: "C2", color: "bg-white", gridArea: "C2", label: "C2", clickable: true },
  { id: "D2", color: "bg-white", gridArea: "D2", label: "D2", clickable: true },
  { id: "E2", color: "bg-white", gridArea: "E2", label: "E2", clickable: true },
  { id: "F2", color: "bg-white", gridArea: "F2", label: "F2", clickable: true },
  { id: "G2", color: "bg-white", gridArea: "G2", label: "G2", clickable: true },
  { id: "H2", color: "bg-white", gridArea: "H2", label: "H2", clickable: true },
  { id: "I2", color: "bg-white", gridArea: "I2", label: "I2", clickable: true },
  { id: "J2", color: "bg-white", gridArea: "J2", label: "J2", clickable: true },
  { id: "K2", color: "bg-white", gridArea: "K2", label: "K2", clickable: true },
  { id: "L2", color: "bg-white", gridArea: "L2", label: "L2", clickable: true },
  { id: "M2", color: "bg-white", gridArea: "M2", label: "M2", clickable: true },
  { id: "N2", color: "bg-white", gridArea: "N2", label: "N2", clickable: true },
  { id: "O2", color: "bg-white", gridArea: "O2", label: "O2", clickable: true },
  { id: "P2", color: "bg-white", gridArea: "P2", label: "P2", clickable: true },
  { id: "Q2", color: "bg-white", gridArea: "Q2", label: "Q2", clickable: true },
  { id: "R2", color: "bg-white", gridArea: "R2", label: "R2", clickable: true },
  { id: "S2", color: "bg-white", gridArea: "S2", label: "S2", clickable: true },
  { id: "T2", color: "bg-white", gridArea: "T2", label: "T2", clickable: true },
  { id: "U2", color: "bg-white", gridArea: "U2", label: "U2", clickable: true },
  { id: "V2", color: "bg-white", gridArea: "V2", label: "V2", clickable: true },
  { id: "W2", color: "bg-white", gridArea: "W2", label: "W2", clickable: true },
  { id: "X2", color: "bg-white", gridArea: "X2", label: "X2", clickable: true },
  { id: "Y2", color: "bg-white", gridArea: "Y2", label: "Y2", clickable: true },
  { id: "Z2", color: "bg-white", gridArea: "Z2", label: "Z2", clickable: true },
  { id: "A3", color: "bg-white", gridArea: "A3", label: "A3", clickable: true },
  { id: "B3", color: "bg-white", gridArea: "B3", label: "B3", clickable: true },
  { id: "C3", color: "bg-white", gridArea: "C3", label: "C3", clickable: true },
  { id: "D3", color: "bg-white", gridArea: "D3", label: "D3", clickable: true },
  { id: "E3", color: "bg-white", gridArea: "E3", label: "E3", clickable: true },
  { id: "F3", color: "bg-white", gridArea: "F3", label: "F3", clickable: true },
  { id: "G3", color: "bg-white", gridArea: "G3", label: "G3", clickable: true },
  { id: "H3", color: "bg-white", gridArea: "H3", label: "H3", clickable: true },
];

const legendItems = [
  { color: "bg-orange-500", label: "Cajas con producto" },
  { color: "bg-yellow-800", label: "Palet con cajas vacias" },
  { color: "bg-blue-500", label: "Caja con bolsas" },
  { color: "bg-slate-500", label: "Cajas Vacias" },
  { color: "bg-pink-300", label: "Palet y cajas vacias" },
];

export default function WarehouseMap() {
  const [selectedSection, setSelectedSection] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [currentLocationIndex, setCurrentLocationIndex] = useState(0);
  const [highlightedLocations, setHighlightedLocations] = useState([]);
  const [products, setProducts] = useState([]);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const [editingIndex, setEditingIndex] = useState(null); // Índice del estante que está siendo editado
  const [editedProductName, setEditedProductName] = useState(""); // Nombre del producto editado
  const [editingShelf, setEditingShelf] = useState(""); // Nombre del estante que está siendo editado

  const [recharge, setRecharge] = useState(false);
  const [zonasProds, setZonasProds] = useState([]);
  const [productsAll, setProductsAll] = useState([]);

  const handleEditClick = (index, productName, shelfName) => {
    setEditingIndex(index);
    setEditedProductName(productName);
    setEditingShelf(shelfName);
  };

  const handleSaveClick = async (index, shelfName) => {
    // Aquí se debería realizar la llamada a la API para actualizar el estante correspondiente
    const updatedData = {
      estante: shelfName, // Estante (estante_1, estante_2, etc.)
      nuevoNombre: editedProductName, // Nuevo nombre del producto
    };

	console.log(updatedData);

    setEditingIndex(null);
    setEditedProductName("");
    setEditingShelf("");

    try {
      const response = await fetch(
        `${BACKEND_URL}/zonas/upZonas/${selectedSection.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      setRecharge(!recharge);
      setSelectedSection(null);
	  
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/zonas/getZonas`);
      const data = await response.json();
      // Convertir el formato para que los estantes se manejen como una lista de productos por zona
      const formattedData = data.map((zone) => ({
        name: [
          zone.estante_1,
          zone.estante_2,
          zone.estante_3,
          zone.estante_4,
          zone.estante_5,
        ],
        locations: [zone.nombre_zona], // Ahora las zonas son individuales
      }));
      setProducts(formattedData);
      const prods = formattedData.flatMap((product) => product.name);
      const set = new Set(prods);
      setOptions(Array.from(set));


	  const response2 = await fetch(`${BACKEND_URL}/products/getAll`);

	  if (!response2.ok) {
		throw new Error("Network response was not ok");
	  }

	  const data2 = await response2.json();

	  setProductsAll(data2.map((product) => product.name));


    } catch (error) {
      console.error(error);
    }
  };

  // Recargar productos al montar el componente
  useEffect(() => {
    fetchProducts();
  }, [recharge]);

  const handleSectionClick = (section) => {
    if (section.clickable) {
      const sectionResults = products.filter((product) =>
        product.locations.includes(section.id)
      );
      setSearchResults(sectionResults);
      setSelectedSection(section);
      setHighlightedLocations(
        sectionResults.flatMap((result) => result.locations)
      );
      setCurrentLocationIndex(0);
    }
  };

  const handleBackClick = () => {
    setSelectedSection(null);
    setCurrentLocationIndex(0);
  };

  const handleQrDetected = (code) => {
    const section = sections.find((s) => s.id === code);
    if (section) {
      const sectionResults = products.filter((product) =>
        product.locations.includes(section.id)
      );
      setSearchResults(sectionResults);
      setSelectedSection(section);
      setIsScannerOpen(false); // Cerrar el modal del escáner al detectar el QR
    } else {
      alert("Sección no encontrada");
    }
  };

  const shelfNames = [
    "estante_1",
    "estante_2",
    "estante_3",
    "estante_4",
    "estante_5",
  ];

  useEffect(() => {
    if (highlightedLocations.length > 0) {
      const zonesToHighlight = products
        .filter((product) =>
          product.name.some((p) => highlightedLocations.includes(p))
        )
        .flatMap((product) => product.locations);

      setZonasProds(zonesToHighlight);
    } else {
      setZonasProds([]);
    }
  }, [highlightedLocations, products]);

  return (
    <div className="relative w-full min-h-screen p-4 sm:p-8 mt-16">
      <div className="flex items-center gap-2 w-full">
        <SelectDataPersonalized
          options={options}
          styles={"w-full"}
          setSelectedFilters={setHighlightedLocations}
        />
        <div className="flex gap-2 mt-2 sm:mt-0">
          <button
            type="button"
            onClick={() => setIsScannerOpen(true)}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            <QrCode className="w-4 h-4" />
          </button>
        </div>
      </div>

      <ScannerModal
        isOpen={isScannerOpen}
        onClose={() => setIsScannerOpen(false)}
        onDetected={handleQrDetected}
      />

      <AnimatePresence>
        {selectedSection && (
          <motion.div
            key="detail"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20 p-4 sm:p-8"
          >
            <div className="bg-white p-4 sm:p-8 rounded-lg shadow-lg max-w-2xl w-full mt-16">
              <h2 className="text-2xl sm:text-4xl font-bold mb-4">{`Sección ${selectedSection.id}`}</h2>
              {searchResults.length > 0 && (
                <>
                  <div className="overflow-x-auto pb-24">
                    <table className="table-auto border-collapse border border-gray-300 mx-auto mb-4 w-full">
                      <thead>
                        <tr>
                          <th className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2">
                            Nombre del Producto
                          </th>
                          <th className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2">
                            Acción
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResults[currentLocationIndex].name.map(
                          (productName, index) => (
                            <tr key={index}>
                              <td className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2">
                                {editingIndex === index ? (
								  <SelectData options={productsAll} styles1={95} prev={productName.split("+").map(item => item.trim())} setEditedProductName={setEditedProductName}/>
                                ) : (
                                  productName
                                )}
                              </td>
                              <td className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2">
                                {editingIndex === index ? (
                                  <button
                                    onClick={() =>
                                      handleSaveClick(index, shelfNames[index])
                                    }
                                    className="flex justify-center items-center text-green-500"
                                  >
                                    <Save className="w-4 h-4 mr-1" />
                                    Guardar
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleEditClick(
                                        index,
                                        productName,
                                        shelfNames[index]
                                      )
                                    }
                                    className="flex justify-center items-center text-blue-500"
                                  >
                                    <Edit className="w-4 h-4 mr-1" />
                                    Editar
                                  </button>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
                <button
                  onClick={handleBackClick}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full sm:w-auto"
                >
                  Volver
                </button>
                <ZoneModal selectedSection={selectedSection} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        key="map"
        className="grid grid-cols-18 grid-rows-9 gap-1 h-[calc(100vh-10rem)] sm:h-[calc(100vh+35rem)] mt-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          gridTemplateAreas: `
        "DIV1               DIV1                DIV1  DIV1   DIV1           DIV1   DIV1   DIV1            DIV1  DIV1  DIV1                     DIV1  DIV1                   DIV1                   DIV1                   DIV1                  DIV1               DIV1"
				"A                  CAJAS-CON-PRODUCTOS D     G      CAJAS-VACIAS   J      M      CAJA-CON-BOLSAS P     S     CAJAS-VACIASS            V     Y                      CAJAS-VACIASSS         AB                     PALET-Y-CAJAS-VACIAS  AE                 AE"
				"A                  CAJAS-CON-PRODUCTOS D     G      CAJAS-VACIAS   J      M      .               P     S     .                        V     Y                      CAJAS-VACIASSS         AB                     PALET-Y-CAJAS-VACIAS  AF                 AF"
				"B                  CAJAS-CON-PRODUCTOS E     H      CAJAS-VACIAS   K      N      .               Q     T     .                        W     Z                      CAJAS-VACIASSS         AC                     PALET-Y-CAJAS-VACIAS  .                  CAJA-CON-PRODUCTO"
				"B                  CAJAS-CON-PRODUCTOS E     H      CAJAS-VACIAS   K      N      .               Q     T     .                        W     Z                      CAJAS-VACIASSS         AC                     PALET-Y-CAJAS-VACIAS  AI                 AI"
				"C                  CAJAS-CON-PRODUCTOS F     I      CAJAS-VACIAS   L      O      .               R     U     .                        X     AA                     CAJAS-VACIASSS         PALET-CON-CAJAS-VACIAS PALET-Y-CAJAS-VACIAS  AJ                 AJ"
				"C                  CAJAS-CON-PRODUCTOS F     I      CAJAS-VACIAS   L      O      .               R     U     .                        X     AA                     CAJAS-VACIASSS         PALET-CON-CAJAS-VACIAS PALET-Y-CAJAS-VACIAS  .                  ."
				".                  .                   .     .      .              .      .      .               .     .     .                        .     .                      .                      .                      .                     .                  ."
				".                  .                   .     .      .              AQ     .      .               .     .     .                        .     .                      .                      .                      .                     .                  ."
				"AS                 AS                  AR    AR     .              AQ     AP     AP              AO    AO    PALET-Y-CAJAS-VACIASSSSS .     PALET-Y-CAJAS-VACIASSS PALET-Y-CAJAS-VACIASSS PALET-Y-CAJAS-VACIASS  PALET-Y-CAJAS-VACIASS AK                 AK"
			  "DIV                DIV                 DIV   DIV    DIV            DIV    DIV    DIV             DIV   DIV   DIV                      DIV   DIV                    DIV                    DIV                    DIV                   DIV                DIV "
        "A1                 .                   B1    B1     .              D1     D1     F1              F1    H1    H1                       J1    .                      .                      K1                     K1                     M1                N1"
        "A1                 .                   C1    C1     .              E1     E1     G1              G1    I1    I1                       J1    .                      .                      L1                     L1                     .                 ."
        ".                  .                   .     .      .              .      .      .               .     .     .                        .     .                      .                      .                      .                      .                 ."
        "O1                 .                   P1    P1     R1             R1     T1     T1              V1    V1    X1                       .     .                      .                      .                      .                      Y1                Z1"
        "O1                 .                   Q1    Q1     S1             S1     U1     U1              W1    W1    X1                       .     .                      .                      .                      .                      A2                B2"
        "C2                 .                   .     .      .              .      .      .               .     .     .                        .     .                      .                      .                      .                      .                 . "
        "C2                 .                   D2    D2     F2             F2     H2     H2              J2    K2    L2                       .     .                      .                      .                      .                      M2                N2"            
        "Q2                 .                   E2    E2     G2             G2     I2     I2              J2    K2    L2                       .     .                      .                      .                      .                      O2                P2"
        "Q2                 .                   .     .      .              .      .      .               .     .     .                        .     .                      .                      .                      .                      .                 ."
        "R2                 .                   S2    S2     U2             U2     W2     W2              Y2    Z2    .                        .     .                      A3                     B3                     C3                     D3                E3"
        "R2                 .                   T2    T2     V2             V2     X2     X2              Y2    Z2    .                        .     .                      A3                     B3                     C3                     F3                G3"
        ".                  .                   .     .      .              .      .      .               .     .     .                        .     .                      .                      .                      .                      .                 ."
        "CAJAS-VACIASSSSS   .                   .     .      .              .      .      .               .     .     .                        .     .                      .                      .                      CAJAS-VACIASSSS        CAJAS-VACIASSSS   H3"
        `,
        }}
      >
        {sections.map((section) => (
          <motion.div
            key={section.id}
            style={{
              gridArea: section.gridArea,
            }}
            className={`flex items-center justify-center text-[0.5rem] sm:text-xs md:text-sm lg:text-lg font-bold ${
              section.clickable ? "cursor-pointer" : "cursor-default"
            } ${section.color} ${
              zonasProds.includes(section.id) ? "ring-4 ring-green-500" : ""
            } shadow-lg overflow-hidden rounded-lg`}
            whileHover={section.clickable ? { scale: 0.95 } : {}}
            whileTap={section.clickable ? { scale: 0.95 } : {}}
            onClick={() => handleSectionClick(section)}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="h-full w-full flex items-center justify-center"
            >
              {section.label}
            </motion.div>
          </motion.div>
        ))}
      </motion.div>

      <div className="mt-8">
        <h3 className="text-xl font-bold mb-2">Leyenda</h3>
        <div className="flex flex-wrap gap-4">
          {legendItems.map((item, index) => (
            <div key={index} className="flex items-center">
              <div className={`w-6 h-6 mr-2 rounded ${item.color}`}></div>
              <span className="text-xs sm:text-sm">{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
