import React from "react";
import { Bar, BarChart, CartesianGrid, LabelList, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function RecipeBarDesktop({ handleClick, chartData, previousClick, ventaMostrador = [] }) {
	return (
		<div className="hidden md:block">
			<ResponsiveContainer width={"100%"} aspect={16.0 / 5.0}>
				<BarChart
					onClick={handleClick}
					width={500}
					height={300}
					data={
						chartData && chartData.length > 0
							? chartData.map((x) => {
									// Verificar que ventaMostrador sea un array antes de aplicar filter
									let ventaMostradorApm = Array.isArray(ventaMostrador)
										? ventaMostrador.filter((venta) => venta["apmCarga"] === x.apmCarga)
										: [];

									let total = ventaMostradorApm.length > 0 ? ventaMostradorApm[0].totalCantidad : 0;

									return {
										"Total de Productos Recetados": x.totalCantidad - total,
										"Venta Mostrador": total,
										total: x.totalCantidad,
										name: x.apmCarga,
									};
							  })
							: []
					}
					margin={{
						top: 0,
						right: 30,
						left: -10,
						bottom: 105,
					}}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" fontSize={"16px"} angle={-45} interval={0} textAnchor="end" />
					<YAxis />
					<Tooltip />
					<Legend
						verticalAlign="top"
						wrapperStyle={{
							textAlign: "center", // Centrar horizontalmente
							marginTop: "-10px",
							position: "relative",
							width: "100%", // Asegurar que ocupa todo el ancho
						}}
					/>

					<Bar
						dataKey="Total de Productos Recetados"
						fill="#d46611"
						activeBar={<Rectangle fill="pink" stroke="blue" />}
						stackId={"a"}></Bar>
					{ventaMostrador.length > 0 ? (
						<Bar dataKey="Venta Mostrador" fill="#8A2BE2" stackId={"a"}>
							<LabelList dataKey="total" position="top" fontSize={"12px"} />
						</Bar>
					) : null}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
}
